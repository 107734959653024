export const positive = [
  { text: "cheers", value: 6 },
  { text: "adventure", value: 6 },
  { text: "huge", value: 6 },
  { text: "expand", value: 6 },
  { text: "pretty", value: 6 },
  { text: "interest", value: 6 },
  { text: "opportunities", value: 6 },
  { text: "inspired", value: 6 },
  { text: "chance", value: 6 },
  { text: "loved", value: 6 },
  { text: "kind", value: 6 },
  { text: "exciting", value: 7 },
  { text: "responsible", value: 7 },
  { text: "enjoy", value: 7 },
  { text: "opportunity", value: 7 },
  { text: "leadership", value: 7 },
  { text: "favorite", value: 7 },
  { text: "wow", value: 8 },
  { text: "yes", value: 8 },
  { text: "improve", value: 8 },
  { text: "impressive", value: 8 },
  { text: "welcome", value: 8 },
  { text: "interesting", value: 8 },
  { text: "glad", value: 8 },
  { text: "support", value: 8 },
  { text: "passion", value: 9 },
  { text: "thrilled", value: 9 },
  { text: "eager", value: 9 },
  { text: "beautiful", value: 9 },
  { text: "care", value: 9 },
  { text: "successful", value: 9 },
  { text: "fresh", value: 9 },
  { text: "strong", value: 9 },
  { text: "meaningful", value: 9 },
  { text: "hopefully", value: 10 },
  { text: "keen", value: 10 },
  { text: "top", value: 10 },
  { text: "thanks", value: 10 },
  { text: "helps", value: 11 },
  { text: "reach", value: 11 },
  { text: "luxury", value: 11 },
  { text: "innovation", value: 12 },
  { text: "greetings", value: 12 },
  { text: "launched", value: 12 },
  { text: "talent", value: 12 },
  { text: "beauty", value: 12 },
  { text: "helping", value: 14 },
  { text: "solutions", value: 14 },
  { text: "join", value: 15 },
  { text: "fun", value: 15 },
  { text: "want", value: 15 },
  { text: "growing", value: 15 },
  { text: "please", value: 15 },
  { text: "share", value: 18 },
  { text: "growth", value: 18 },
  { text: "hoping", value: 18 },
  { text: "big", value: 20 },
  { text: "nice", value: 21 },
  { text: "amazing", value: 22 },
  { text: "interested", value: 23 },
  { text: "leading", value: 24 },
  { text: "fan", value: 25 },
  { text: "super", value: 25 },
  { text: "passionate", value: 25 },
  { text: "hope", value: 25 },
  { text: "focused", value: 27 },
  { text: "better", value: 28 },
  { text: "free", value: 31 },
  { text: "good", value: 32 },
  { text: "happy", value: 33 },
  { text: "like", value: 42 },
  { text: "best", value: 43 },
  { text: "help", value: 49 },
  { text: "great", value: 59 },
  { text: "creative", value: 61 },
  { text: "love", value: 78 },
  { text: "excited", value: 130 },
]
export const negative = [
  { text: "debt", value: 2 },
  { text: "crush", value: 2 },
  { text: "stuck", value: 2 },
  { text: "hollow", value: 2 },
  { text: "boring", value: 2 },
  { text: "stress", value: 2 },
  { text: "blah", value: 2 },
  { text: "fear", value: 2 },
  { text: "exploit", value: 2 },
  { text: "emergency", value: 2 },
  { text: "hate", value: 3 },
  { text: "disruption", value: 3 },
  { text: "risk", value: 3 },
  { text: "sorry", value: 3 },
  { text: "problem", value: 4 },
  { text: "leave", value: 4 },
  { text: "miss", value: 4 },
  { text: "disruptive", value: 4 },
  { text: "bad", value: 4 },
  { text: "hard", value: 6 },
  /* { text: "challenge", value: 11 }, */
  { text: "no", value: 16 },
]

export const all = [
  { text: "brand", value: 293 },
  { text: "marketing", value: 221 },
  { text: "everyone", value: 199 },
  { text: "strategy", value: 153 },
  { text: "sprint", value: 134 },
  { text: "years", value: 132 },
  { text: "work", value: 129 },
  { text: "excited", value: 127 },
  { text: "business", value: 123 },
  { text: "company", value: 114 },
  { text: "new", value: 114 },
  { text: "working", value: 112 },
  { text: "learn", value: 108 },
  { text: "currently", value: 88 },
  { text: "learning", value: 86 },
  { text: "digital", value: 84 },
  { text: "also", value: 82 },
  { text: "love", value: 77 },
  { text: "brands", value: 76 },
  { text: "next", value: 75 },
  { text: "agency", value: 71 },
  { text: "experience", value: 69 },
  { text: "name", value: 67 },
  { text: "get", value: 63 },
  { text: "group", value: 63 },
  { text: "tech", value: 63 },
  { text: "meet", value: 62 },
  { text: "creative", value: 60 },
  { text: "people", value: 59 },
  { text: "great", value: 58 },
  { text: "time", value: 56 },
  { text: "branding", value: 54 },
  { text: "connect", value: 54 },
  { text: "director", value: 54 },
  { text: "world", value: 53 },
  { text: "industry", value: 51 },
  { text: "media", value: 50 },
  { text: "one", value: 50 },
  { text: "course", value: 50 },
  { text: "prof", value: 50 },
  { text: "help", value: 49 },
  { text: "design", value: 48 },
  { text: "last", value: 48 },
  { text: "product", value: 48 },
  { text: "management", value: 48 },
  { text: "global", value: 47 },
  { text: "worked", value: 46 },
  { text: "startup", value: 46 },
  { text: "really", value: 44 },
  { text: "content", value: 44 },
  { text: "part", value: 44 },
  { text: "companies", value: 43 },
]

/* export const postive = [
  { text: "cheers", value: 6 },
  { text: "adventure", value: 6 },
  { text: "huge", value: 6 },
  { text: "expand", value: 6 },
  { text: "pretty", value: 6 },
  { text: "interest", value: 6 },
  { text: "opportunities", value: 6 },
  { text: "inspired", value: 6 },
  { text: "chance", value: 6 },
  { text: "loved", value: 6 },
  { text: "kind", value: 6 },
  { text: "exciting", value: 7 },
  { text: "responsible", value: 7 },
  { text: "enjoy", value: 7 },
  { text: "opportunity", value: 7 },
  { text: "leadership", value: 7 },
  { text: "favorite", value: 7 },
  { text: "wow", value: 8 },
  { text: "yes", value: 8 },
  { text: "improve", value: 8 },
  { text: "impressive", value: 8 },
  { text: "welcome", value: 8 },
  { text: "interesting", value: 8 },
  { text: "glad", value: 8 },
  { text: "support", value: 8 },
  { text: "passion", value: 9 },
  { text: "thrilled", value: 9 },
  { text: "eager", value: 9 },
  { text: "beautiful", value: 9 },
  { text: "care", value: 9 },
  { text: "successful", value: 9 },
  { text: "fresh", value: 9 },
  { text: "strong", value: 9 },
  { text: "meaningful", value: 9 },
  { text: "hopefully", value: 10 },
  { text: "keen", value: 10 },
  { text: "top", value: 10 },
  { text: "thanks", value: 10 },
  { text: "helps", value: 11 },
  { text: "reach", value: 11 },
  { text: "luxury", value: 11 },
  { text: "innovation", value: 12 },
  { text: "greetings", value: 12 },
  { text: "launched", value: 12 },
  { text: "talent", value: 12 },
  { text: "beauty", value: 12 },
  { text: "helping", value: 14 },
  { text: "solutions", value: 14 },
  { text: "join", value: 15 },
  { text: "fun", value: 15 },
  { text: "want", value: 15 },
  { text: "growing", value: 15 },
  { text: "please", value: 15 },
  { text: "share", value: 18 },
  { text: "growth", value: 18 },
  { text: "hoping", value: 18 },
  { text: "big", value: 20 },
  { text: "nice", value: 21 },
  { text: "amazing", value: 22 },
  { text: "interested", value: 23 },
  { text: "leading", value: 24 },
  { text: "fan", value: 25 },
  { text: "super", value: 25 },
  { text: "passionate", value: 25 },
  { text: "hope", value: 25 },
  { text: "focused", value: 27 },
  { text: "better", value: 28 },
  { text: "free", value: 31 },
  { text: "good", value: 32 },
  { text: "happy", value: 33 },
  { text: "like", value: 42 },
  { text: "best", value: 43 },
  { text: "help", value: 49 },
  { text: "great", value: 59 },
  { text: "creative", value: 61 },
  { text: "love", value: 78 },
  { text: "excited", value: 130 },
]
 */
